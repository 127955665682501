import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FC } from 'react';
import { motion } from 'framer-motion';

const Footer: FC = () => {
  const variants = {
    hover: { scale: 1.1 }
  };

  return (
    <footer className="bg-black text-gray-300 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h2 className="text-xl font-bold text-white mb-4">Refuge Studios</h2>
            <p className="text-gray-400">
              Innovating the future, <br />one pixel at a time.
            </p>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
            <ul>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="/" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">Home</Link>
              </motion.li>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="/about" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">About</Link>
              </motion.li>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="/contact" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">Contact</Link>
              </motion.li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold text-white mb-4">Follow Us</h3>
            <ul className="flex space-x-4">
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="https://x.com/Refuge_Studios" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faTwitter} className="w-8 h-auto" />
                </Link>
              </motion.li>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="https://www.facebook.com/profile.php?id=61564090825628" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faFacebook} className="w-8 h-auto" />
                </Link>
              </motion.li>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="https://www.linkedin.com/company/refuge-studios" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faLinkedin} className="w-8 h-auto" />
                </Link>
              </motion.li>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="https://www.instagram.com/RefugeStudios_/" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faInstagram} className="w-8 h-auto" />
                </Link>
              </motion.li>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  <FontAwesomeIcon icon={faYoutube} className="w-8 h-auto" />
                </Link>
              </motion.li>
            </ul>
          </div>

          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
            <ul>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="/policies/privacy"  target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  Privacy Policy
                </Link>
              </motion.li>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="/policies/terms"  target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  Terms of Use
                </Link>
              </motion.li>
              <motion.li whileHover="hover" variants={variants} className="mb-3">
                <Link href="/policies/content"  target="_blank" rel="noopener noreferrer" className="hover:text-orange-400 transition duration-300">
                  Content Policy
                </Link>
              </motion.li>
            </ul>
          </div>

          <div className="w-full md:w-1/4">
            <h3 className="text-lg font-semibold text-white mb-4">Contact Us</h3>
            <p className="text-gray-400 mb-2">Email: info@refugestudios.com.au</p>
          </div>


        </div>
        <div className="mt-8 text-center text-gray-400 text-sm">
          &copy; 2024 Refuge Studios. All rights reserved.
        </div>
      </div>
    </footer>
  );
};


export default Footer;

import Link from 'next/link';
import Image from 'next/image';
import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@components/store/store';
import { clearUser } from '@components/store/auth-slice';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar: FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  const handleLogout = () => {
    if (!user) return;

    dispatch(clearUser());
    router.push('/');
  };

  const menuVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <nav className="bg-black text-white shadow-md fixed w-full top-0 left-0 z-50">
      <div className="container mx-auto px-6 py-4 flex items-center justify-between">
        <Link href="/" passHref className="flex items-center space-x-2 cursor-pointer">
          <Image src="/logo.png" alt="Refuge Studios Logo" width={40} height={40} />
          <span className="text-2xl font-bold">Refuge Studios</span>
        </Link>
        <div className="hidden md:flex items-center space-x-6">
          <Link href="/voxelizer" className="hover:underline cursor-pointer">Voxelizer</Link>
          <Link href="/chitchat" className="hover:underline cursor-pointer">ChitChat</Link>

          <motion.div
            initial="hidden"
            whileHover="hover"
            animate="visible"
            variants={{
              hover: { scale: 1.1 },
            }}
            className="text-white text-sm bg-gradient-to-r from-pink-500 to-orange-500 py-2 px-5 rounded-full hover:bg-gradient-to-r hover:from-pink-600 hover:to-orange-600 cursor-pointer transition-colors duration-300"
          >
            <Link href="/signin" onClick={handleLogout}>
              {user ? 'Logout' : 'Login'}
            </Link>
          </motion.div>
        </div>
        <button
          className="md:hidden text-2xl focus:outline-none"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <span>&#9776;</span> {/* Hamburger icon */}
        </button>
      </div>
      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={menuVariants}
            transition={{ duration: 0.3 }}
            className="md:hidden absolute top-16 left-0 w-full bg-black text-white"
          >
            <div className="container mx-auto px-6 py-4 space-y-4">
              <Link href="/about">
                <div className="block cursor-pointer">About Us</div>
              </Link>
              <Link href="/voxelizer">
                <div className="block cursor-pointer">Voxelizer</div>
              </Link>
              <Link href="/chitchat">
                <div className="block cursor-pointer">ChitChat</div>
              </Link>
              <Link href="/contact">
                <div className="block cursor-pointer">Contact</div>
              </Link>
              <motion.div
                initial="hidden"
                whileHover="hover"
                animate="visible"
                variants={{
                  hover: { scale: 1.1 },
                }}
                className="text-white text-sm bg-gradient-to-r from-pink-500 to-orange-500 py-2 px-5 rounded-full hover:bg-gradient-to-r hover:from-pink-600 hover:to-orange-600 cursor-pointer transition-colors duration-300"
              >
                <Link href="/signin" onClick={handleLogout}>
                  {user ? 'Logout' : 'Login'}
                </Link>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
